import React from 'react'

import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

export default function Collapse(props) {
  return (
    <SlideDown className={'collapse'}>
      {props.open ? props.children : null}
    </SlideDown>
  )
}