import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import remark from 'remark'
import remarkHTML from 'remark-html'
import Banner from "../components/banner"
import Collapse from "../components/collapse"

const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

export default function Template({ data, pageContext }) {

  const bannerUrl = "/workshops/workshops-banner.jpg";

  const [accordianOpen, setAccordianOpen] = React.useState(-1);

  return (
    <Layout title={pageContext.title} description="Event information.">
      <Banner title={pageContext.title} imageUrl={bannerUrl}/>

      <section className="section">
        <div className="container thin">
          <div style={{width: "100%"}}>
            <img src={pageContext.image} alt="Event image" style={{objectPosition:"50% 33%", objectFit: "cover", width: "100%", height: "340px"}}/>
          </div>
        </div>
      </section>
      <section className="section no-top-padding">
        <div className="container thin">
          <div className="content" dangerouslySetInnerHTML={{__html: toHTML(pageContext.description)}} />
        </div>
      </section>
    </Layout>
  )
}
